import React from 'react'
import QuoteCarouselBlade from 'components/QuoteCarousel/QuoteCarouselBlade'
import unicodeNonbreakingSpace from 'styles/constants/unicodeNonbreakingSpace'
import useCustomerQuotes from 'hooks/useCustomerQuotes'

const WhatCustomersAreSaying: React.FunctionComponent = () => {
  const quotes = useCustomerQuotes()

  return (
    <QuoteCarouselBlade
      quotes={quotes}
      title={`What our customers are${unicodeNonbreakingSpace}saying`}
    />
  )
}

export default WhatCustomersAreSaying

import React from 'react'
import DemoBlade from 'components/DemoBlade'

const Intro: React.FunctionComponent = () => (
  <DemoBlade
    title="Instantly visualize your project’s status"
    subtitle="Connect cards across your Trello boards and see their progress
      at&nbsp;a&nbsp;glance without opening cards, expanding a list, or
      relying&nbsp;on&nbsp;checklists."
  />
)

export default Intro

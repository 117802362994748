import React from 'react'

import {useStaticQuery, graphql} from 'gatsby'
import {InstantCredibilityBladeQuery} from 'landing-gatsby-graphql-generated'
import invariant from 'tiny-invariant'
import AssetBlade, {Asset} from './AssetBlade'
import Headline from '../Headline'
import BodyText from '../BodyText'
import CustomerGrid from '../CustomerGrid'

const InstantCredibility = () => {
  const data = useStaticQuery<InstantCredibilityBladeQuery>(graphql`
    query InstantCredibilityBlade {
      createCard: file(relativePath: {eq: "create-card.png"}) {
        ...FeatureAsset
      }
    }
  `)
  const createCard = data.createCard?.childImageSharp?.fluid
  invariant(createCard, 'expected to find createCard')

  const asset: Asset = {
    src: createCard,
    placement: 'left',
    alt: 'create children',
  }

  return (
    <AssetBlade asset={asset}>
      <Headline>
        Hello Epics helps thousands of teams get more out of Trello.
      </Headline>
      <BodyText>
        Over one million cards created across 100,000+ boards.
      </BodyText>
      <CustomerGrid />
    </AssetBlade>
  )
}

export default InstantCredibility

import React from 'react'
import styled from '@emotion/styled'
import {breakpoint, padding, margin} from 'styles'
import petco from 'images/customers/petco.svg'
import heroku from 'images/customers/heroku.svg'
import trello from 'images/customers/trello.svg'
import glassdoor from 'images/customers/glassdoor.svg'
import spotify from 'images/customers/spotify.svg'

interface Customer {
  name: string
  image: {
    src: string
    maxWidth?: number
  }
}

const customers: Customer[] = [
  {
    name: 'Spotify',
    image: {
      src: spotify,
    },
  },
  {
    name: 'Petco',
    image: {
      src: petco,
      maxWidth: 90,
    },
  },
  {
    name: 'Heroku',
    image: {
      src: heroku,
      maxWidth: 40,
    },
  },
  {
    name: 'Trello',
    image: {
      src: trello,
    },
  },
  {
    name: 'Glassdoor',
    image: {
      src: glassdoor,
      maxWidth: 75,
    },
  },
]

const Grid = styled.ul({
  ...margin.top(48),
  ...margin.bottomBlade(),
  listStyle: 'none',
  opacity: 0.67,
  '& img': {
    borderRadius: 3,
    width: '100%',
  },

  // grid fallback
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  '& li': {
    ...padding(24),
    maxWidth: 132,
  },

  '@supports (display: grid)': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(min-content, 90px))',
    gridGap: 36,
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    [breakpoint.largeAndUp]: {
      gridTemplateColumns: `repeat(${customers.length}, minmax(min-content, 120px))`,
    },
    '& li': {
      ...padding(0),
      maxWidth: 'none',
    },
  },
  [breakpoint.mediumAndUp]: {
    ...margin.bottom(24), // two column layout
  },
})

const CustomerGrid = () => (
  <Grid>
    {customers.map((customer) => (
      <li key={customer.name}>
        <img
          src={customer.image.src}
          alt={customer.name}
          title={customer.name}
          style={{maxWidth: customer.image.maxWidth}}
        />
      </li>
    ))}
  </Grid>
)

export default CustomerGrid

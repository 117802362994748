import React from 'react'
import styled from '@emotion/styled'
import {typography, margin, breakpoint} from 'styles'
import Wrapper from 'components/Wrapper'

import image1 from 'images/popular-uses-icons/1.svg'
import image2 from 'images/popular-uses-icons/2.svg'
import image3 from 'images/popular-uses-icons/3.svg'
import image4 from 'images/popular-uses-icons/4.svg'
import image5 from 'images/popular-uses-icons/5.svg'
import image6 from 'images/popular-uses-icons/6.svg'
import unicodeNonbreakingSpace from 'styles/constants/unicodeNonbreakingSpace'
import ThreeColumnGrid from 'components/ThreeColumnGrid'
import Card, {CardProps} from 'components/ThreeColumnGrid/Card'

const Headline = styled.h3({
  ...typography.headline3,
  textAlign: 'center',

  [breakpoint.mediumAndUp]: {
    ...typography.headline2,
  },
})

const cards: CardProps[] = [
  {
    body:
      'Create separate internal and client-facing boards, and move cards across boards as needed.',
    image: image1,
  },
  {
    body: `Write checklists for all your tasks and automatically convert them into child${unicodeNonbreakingSpace}cards.`,
    image: image2,
  },
  {
    body: 'Group cards based on a common milestone to easily track progress.',
    image: image3,
  },
  {
    body:
      'Mimic an agile development process for other departments, like marketing or operations.',
    image: image4,
  },
  {
    body:
      'Track feature requests by creating cards for each request and grouping them by feature type.',
    image: image5,
  },
  {
    body:
      'Share parent cards with stakeholders and reserve child cards for your project team.',
    image: image6,
  },
]

const PopularUses: React.FunctionComponent = () => (
  <Wrapper>
    <Headline>Popular ways to use Hello&nbsp;Epics</Headline>
    <ThreeColumnGrid
      css={{
        ...margin.top(36),
      }}
    >
      {cards.map((card, index) => (
        <Card {...card} key={index} imageSize={30} imageGap={16} />
      ))}
    </ThreeColumnGrid>
  </Wrapper>
)

export default PopularUses

import React from 'react'
import styled from '@emotion/styled'
import {margin, padding, breakpoint} from 'styles'
import {
  ImageSharpFluid,
  GatsbyImageSharpFluid_WithWebpFragment,
} from 'landing-gatsby-graphql-generated'
import Img from 'gatsby-image'

export interface Asset {
  src:
    | (Pick<ImageSharpFluid, 'presentationWidth'> &
        GatsbyImageSharpFluid_WithWebpFragment)
    | string
  placement: 'left' | 'right'
  alt: string
}

export interface AssetBladeProps {
  asset: Asset
}

const Grid = styled.div([
  {
    // grid falllback
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1200,
    ...margin.x('auto'),

    '@supports (display: grid)': {
      display: 'grid',
      gridTemplateColumns: '1fr minmax(0, 600px) minmax(0, 600px) 1fr',
      maxWidth: 'none',
      ...margin.x(0),
    },
  },
  ({videoPlacement}: {videoPlacement: 'left' | 'right'}) => ({
    [breakpoint.mediumAndUp]: {
      flexDirection: videoPlacement === 'left' ? 'row-reverse' : 'row',
    },
  }),
])

const FeatureAsset: React.FunctionComponent<Pick<AssetBladeProps, 'asset'>> = ({
  asset,
}) => {
  if (typeof asset.src !== 'string') {
    return (
      <Img
        css={{
          maxWidth: asset.src.presentationWidth || '100%',
          margin: '0 auto',
          borderRadius:
            asset.placement === 'right' ? '3px 0px 0px 3px' : '0px 3px 3px 0px',
        }}
        alt={asset.alt}
        fluid={asset.src as any}
      />
    )
  }

  return (
    <img
      css={{
        width: '100%',
        height: 'auto',
        borderRadius:
          asset.placement === 'right' ? '3px 0px 0px 3px' : '0px 3px 3px 0px',
      }}
      alt={asset.alt}
      src={asset.src}
    />
  )
}

const AssetBlade: React.FunctionComponent<AssetBladeProps> = ({
  asset,
  children,
}) => (
  <Grid videoPlacement={asset.placement}>
    <div
      css={{
        gridColumn: '2 / span 2',
        ...padding.x(24),
        ...margin.bottom(16),
        [breakpoint.mediumAndUp]: {
          ...(asset.placement === 'right'
            ? padding.right(48)
            : padding.left(48)),
          // grid falllback
          flex: '1 1 50%',

          gridColumn: asset.placement === 'right' ? 2 : 3,
        },
      }}
    >
      {children}
    </div>
    <div
      css={{
        gridColumn: '2 / span 2',
        [breakpoint.mediumAndUp]: {
          // grid falllback
          flex: '1 1 50%',

          gridColumn: asset.placement === 'right' ? '3 / span 2' : '1 / span 2',
          gridRow: 1,
        },
      }}
    >
      <FeatureAsset asset={asset} />
    </div>
  </Grid>
)

export default AssetBlade

import React from 'react'

import SiteMetadata from 'components/SiteMetadata'
import Intro from 'components/home/blades/Intro'
import InstantCredibility from 'components/home/blades/InstantCredibility'
import PopularUses from 'components/home/blades/PopularUses'
import BottomCTA from 'components/BottomCTA'
import HowToGetStarted from 'components/home/blades/HowToGetStarted'
import WhatCustomersAreSaying from 'components/home/blades/WhatCustomersAreSaying'
import PageWrapper from 'components/PageWrapper'

const IndexPage = () => (
  <PageWrapper smallSpacing={100} mediumAndUpSpacing={200 as any}>
    <SiteMetadata />
    <Intro />
    <InstantCredibility />
    <PopularUses />
    <WhatCustomersAreSaying />
    <HowToGetStarted />
    <BottomCTA />
  </PageWrapper>
)

export default IndexPage
